import routerOptions0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.14.11_@unocss+reset@0.61.5_@upstash+redis@1._vzipl6r3pourzjbk2q65ubina4/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/vercel/path0/node_modules/.pnpm/@nuxt+ui-pro@1.3.1_axios@1.6.8_focus-trap@7.5.4_idb-keyval@6.2.1_jwt-decode@3.1.2_magicast@0._wduaevhrgqpqt32xf7imagpyxa/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}